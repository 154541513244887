<template>
    <div>
        <div id="step-3-content" class="container">
        <div class="row mt-2" v-if="summaryData">
            <div class="col-12 col-md-12 col-lg-12 col-xl-8 img-padding">
            <div class="m-0 dark-blue-text font-weight-bold title-font">
                注文内容をご確認してください
            </div>
            <div
                class="row photo-details-item"
                v-for="(el, i) in summaryData.checkout_data.items"
                :key="i"
            >
                <div class="col-12 col-md-6 col-lg-6 col-xl-6 photo">
                <img
                    class="shadow-2"
                    :src="el.image_url"
                    @click="openModal(el.image_url)"
                    onerror="this.src='/img/image-error.png'"
                />
                <div class="b-aspect d-flex" style="height: 200px">
                    <div class="b-aspect-content flex-grow-1 w-100 mw-100">
                    <div
                        class="b-skeleton b-skeleton-img b-skeleton-animate-wave border-radius-3"
                    ></div>
                    </div>
                </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-6 details detail-font">
                <div class="mt-3">
                    <p class="mb-2">物件名/号室: {{ el.property_name }}</p>
                    <p class="mb-2">画像名: {{ el.filename }}</p>
                    <p class="mb-2">
                    <span>コメント: </span>
                    <span class="text-wrap text-break">{{ el.remarks }}</span>
                    </p>
                    <p class="mb-2" v-if="el.additional_options.length > 0">
                    <span>追加オプション:</span>
                    <span v-for="(_el, _i) in el.additional_options" :key="_i">
                        {{
                        el.additional_options.length !== _i + 1
                            ? _el.name + ','
                            : _el.name
                        }}
                    </span>
                    </p>
                </div>
                </div>
            </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-4 mb-5 price-area">
            <div class="order-details-item detail-font box-padding">
                <div class="dark-blue-text font-weight-bold title-font">ご注文内容</div>
                <div
                class="row mt-4"
                v-for="(el, i) in summaryData.checkout_data.items"
                :key="i"
                >
                <div class="col-8">
                    <p class="m-0 font-weight-bold ellipsis-1">
                    画像#{{ i + 1 }} - {{ el.filename }}
                    </p>
                </div>
                <div class="col-4">
                    <p class="m-0 font-weight-bold text-right">
                    {{ formatPrice(el.total_amount) }}
                    </p>
                </div>
                <div class="col-12 pt-1">
                    <div class="row m-0">
                    <div class="col-6 pr-0 text-left staging-detail">
                        <p class="m-0 ellipsis-1">ステージング</p>
                    </div>
                    <div class="col-4 text-right">
                        <p class="m-0">{{ formatPrice(el.base_amount) }}</p>
                    </div>
                    </div>
                </div>
                <div class="col-12 pt-1" v-if="el.additional_options.length > 0">
                    <div
                    class="row m-0"
                    v-for="(_el, _i) in el.additional_options"
                    :key="_i"
                    >
                    <div class="col-6 pr-0 text-left staging-detail">
                        <p class="m-0 ellipsis-1">{{ _el.name }}</p>
                    </div>
                    <div class="col-4 text-right">
                        <p class="m-0">{{ formatPrice(_el.charge) }}</p>
                    </div>
                    </div>
                </div>
                </div>
                <div class="row mt-4" v-if="summaryData.checkout_data.items.length > 1">
                <div class="col-8" >
                    <p class="m-0 font-weight-bold ellipsis-1">合計</p>
                </div>
                <div class="col-4">
                    <div>
                    <p class="m-0 font-weight-bold text-right">
                        {{ formatPrice((summaryData.checkout_data.overall_amount) - (summaryData.checkout_data.tax_amount)) }}
                    </p>
                    </div>
                </div>
                </div>
                <div v-if="this.code == couponData.code" class="row mt-4">
                <div class="col-8" >
                    <p class="m-0 font-weight-bold ellipsis-1">クーポン適応</p>
                </div>
                <div class="col-4">
                    <div>
                    <p class="m-0 font-weight-bold text-right">
                        -{{ formatPrice(couponData.amount) }}
                    </p>
                    </div>
                </div>
                </div>
                <div>
                <div class="row mt-4" v-if="this.couponData.code">
                    <div class="col-8">
                    <p class="m-0 font-weight-bold ellipsis-1">クーポン適応後金額</p>
                    </div>
                    <div class="col-4">
                    <div v-if="this.couponData.amount">
                        <p class="m-0 font-weight-bold text-right">
                        {{ formatPrice((summaryData.checkout_data.overall_amount - this.couponData.amount) - (summaryData.checkout_data.tax_amount)) }}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
                <div class="row mt-4" v-if="this.couponData.code">
                <div class="col-8">
                    <p class="m-0 font-weight-bold ellipsis-1">クーポン適応後消費税</p>
                </div>
                <div class="col-4">
                    <div v-if="this.couponData.amount">
                    <p class="m-0 font-weight-bold text-right">
                        {{ formatPrice(( summaryData.checkout_data.overall_amount - this.couponData.amount - summaryData.checkout_data.tax_amount) * 10 / 100) }}
                    </p>
                    </div>
                </div>
                </div>
                <div class="row mt-4">
                <div class="col-8" v-if="!this.couponData.code">
                    <p class="m-0 font-weight-bold ellipsis-1">消費税</p>
                </div>
                <div class="col-4">
                    <p v-if="this.code != couponData.code" class="m-0 font-weight-bold text-right">
                    {{ formatPrice(summaryData.checkout_data.tax_amount) }}
                    </p>
                </div>
                </div>
            </div>
            <div class="row mt-4 total-price box-padding">
                <div class="col-12 d-flex align-items-center">
                <span class="m-0 font-weight-bold detail-font ellipsis-1">クーポンコードをお持ちの方:</span>
                </div>
                <div class="coupon-warappaer mt-2">
                <div class="coupon-area">
                    <input id="couponVali" v-if="this.code != couponData.code" class="form-control coupon-btn cursor-default coupon-text" type="text" placeholder="コードを入力してください" v-model="code" />
                    <input v-else disabled class="form-control coupon-btn cursor-default coupon-text" type="text" placeholder="コードを入力してください" v-model="code" />
                    <div class="cou-btn">
                    <button v-if="this.code != couponData.code" type="button" @click="codeConfirm(code)" class="btn-coupon">確認</button>
                    <button v-else disabled type="button" @click="codeConfirm(code)" class="btn-coupon">確認</button>
                    </div>
                </div>
                </div>
                <div class="Adaptation-coupon col-12">
                <div v-if="this.code == couponData.code" class="coupon-description">
                    <p class="m-0 detail-font mt-2">適応したクーポン:</p>
                </div>
                <div v-if="this.code == couponData.code" class="coupon-adap-area detail-font" style="margin-left: 29px;">
                    <div style="text-align:left;">
                    <div style="text-align:left; display:flex;" class="coupon-name mt-2">
                        <div style="margin-left:-10px;">
                        <button v-if="couponData.code == this.code" style="border:none; background:white;">
                            <font-awesome-icon @click="deleCoupon()" :icon="{ prefix: 'fas', iconName: 'times' }" />
                        </button>
                        </div>
                        <span v-if="couponData.code == this.code" style="margin-left:10px;">割引名：{{ couponData.name }}</span>
                    </div>
                    <div class="coupon-amount mt-1" style="text-align:left;">
                        <span v-if="couponData.code == this.code" style="margin-left:22px;">割引額：{{ formatPrice(couponData.amount) }}</span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="row mt-4 py-3 total-price box-padding">
                <div class="col-5 d-flex align-items-center">
                <p class="m-0 font-weight-bold">合計金額:</p>
                </div>
                <div class="col-7 d-flex align-items-center justify-content-end" v-if="this.code != couponData.code">
                <h3 class="m-0 font-weight-bold">
                    {{ formatPrice(summaryData.checkout_data.overall_amount) }}
                </h3>
                </div>
                <div class="col-7 d-flex align-items-center justify-content-end" v-else>
                <div class="total-amount">
                    <h3 v-if="couponData.amount" class="m-0 font-weight-bold">
                    {{ (((summaryData.checkout_data.overall_amount - this.couponData.amount) - (summaryData.checkout_data.tax_amount)) + (( summaryData.checkout_data.overall_amount - this.couponData.amount - summaryData.checkout_data.tax_amount) * 10 / 100)) > 0? formatPrice(((summaryData.checkout_data.overall_amount - this.couponData.amount) - (summaryData.checkout_data.tax_amount)) + (( summaryData.checkout_data.overall_amount - this.couponData.amount - summaryData.checkout_data.tax_amount) * 10 / 100)): formatPrice(0) }}
                    </h3>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div class="row mt-2" v-else>
            <div class="col-12 col-md-12 col-lg-12 col-xl-8 mb-5">
            <div class="row photo-details-item" v-for="(el, i) in 3" :key="i">
                <div class="col-12 col-md-4 col-lg-4 col-xl-4 photo">
                <b-skeleton-img animation="wave"></b-skeleton-img>
                </div>
                <div class="col-12 col-md-8 col-lg-8 col-xl-8 details">
                <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                </div>
            </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-4 mb-5">
            <b-skeleton class="mb-2" animation="wave" width="100px"></b-skeleton>
            <div class="row mt-4" v-for="(el, i) in 3" :key="i">
                <div class="col-8">
                <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                </div>
                <div class="col-4">
                <b-skeleton class="mb-2" animation="wave"></b-skeleton>
                </div>
                <div class="col-12">
                <hr class="mt-1 mb-3" />
                </div>
                <div class="col-12 p-0">
                <div class="row m-0" v-for="(_el, _i) in 5" :key="_i">
                    <div class="col-8 pr-0 text-left">
                    <b-skeleton animation="wave"></b-skeleton>
                    </div>
                    <div class="col-4 text-right">
                    <b-skeleton animation="wave"></b-skeleton>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

        <b-modal
        id="payment-information-modal"
        class="border-radius-2"
        size="lg"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        centered
        >
        <h5
            class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
            v-on:click="destroyStripeElements"
        >
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
        </h5>
        <div class="container text-center">
            <img class="mt-4" src="/img/icons/ico-payment.svg" alt="ico-info" />
            <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">お支払い情報</h5>
            <div class="row mt-4">
            <div class="col-2 p-1">
                <img
                src="/img/icons/ico-card-mastercard.svg"
                alt="ico-card-mastercard"
                />
            </div>
            <div class="col-2 p-1">
                <img src="/img/icons/ico-card-visa.svg" alt="ico-card-visa" />
            </div>
            <div class="col-2 p-1">
                <img
                src="/img/icons/ico-card-dinners-club.svg"
                alt="ico-card-dinners-club"
                />
            </div>
            <div class="col-2 p-1">
                <img src="/img/icons/ico-card-paypal.svg" alt="ico-card-paypal" />
            </div>
            <div class="col-2 p-1">
                <img src="/img/icons/ico-card-jcb.svg" alt="ico-card-jcb" />
            </div>
            <div class="col-2 p-1">
                <img
                src="/img/icons/ico-card-american-express.svg"
                alt="ico-card-american-express"
                />
            </div>
            </div>
            <div class="row pl-sm-5-5 pr-sm-5-5 pb-3 text-left">
            <div class="col-12">
                <div class="mt-4">
                <p class="m-0 font-weight-bold">クレジットカード番号</p>
                <div id="card-number" class="form-control"></div>
                </div>
            </div>
            <div class="col-8">
                <div class="mt-4">
                <p class="m-0 font-weight-bold">有効期限</p>
                <div id="card-expiry" class="form-control"></div>
                </div>
            </div>
            <div class="col-4">
                <div class="mt-4">
                <p class="m-0 font-weight-bold">CVC</p>
                <div id="card-cvc" class="form-control"></div>
                </div>
            </div>
            <div class="col-12">
                <div class="mt-4">
                <p class="m-0 font-weight-bold">カード名義</p>
                <input
                    id="email"
                    class="form-control shadow-1"
                    type="email"
                    minlength="2"
                    maxlength="100"
                    v-model="cardName"
                    v-on:keyup="formMessage = null"
                    placeholder="カード名を入力してください"
                    required
                />
                </div>
            </div>
            <div class="col-12">
                <div id="card-error"></div>
                <b-alert
                class="m-0 mt-3 light-grey-outline grey-text"
                variant="warning"
                show
                v-if="formMessage !== null"
                >
                {{ formMessage }}
                </b-alert>
            </div>
            <div class="col-12 mt-5">
                <button
                class="btn btn-ds dark-blue white-text"
                type="button"
                v-waves.light
                v-on:click="finalizeStaging"
                >
                クレジットカードで支払う
                </button>
            </div>
            </div>
        </div>
        </b-modal>

        <b-modal
        id="payment-success-modal"
        class="border-radius-2"
        size="md"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        centered
        >
        <div class="container text-center">
            <div v-if="isCardError">
            <img
                class="mt-4"
                src="/img/icons/ico-payment-error.svg"
                alt="ico-info"
            />
            <h5 class="mt-4 dark-blue-text font-weight-bold">
                お支払いが失敗しました
            </h5>
            <p class="grey-text">お支払いが失敗し、注文が完了しませんでした</p>
            <div class="row mb-3 mt-5">
                <div class="col-12">
                <button
                    class="btn btn-ds dark-blue white-text pt-2 pb-2"
                    type="button"
                    v-waves.light
                    v-on:click="hidePaymentSuccessModal"
                >
                    OK
                </button>
                </div>
            </div>
            </div>
            <div v-else>
            <img
                class="mt-4"
                src="/img/icons/ico-payment-success.svg"
                alt="ico-info"
            />
            <h5 class="mt-4 dark-blue-text font-weight-bold">
                お支払いが完了しました
            </h5>
            <p class="grey-text">お支払い及び注文が正常に完了しました</p>
            <div class="row mb-3 mt-5">
                <div class="col-12">
                <router-link :to="{ name: 'userRoute-billing-history' }">
                    <button
                    class="btn btn-ds dark-blue white-text pt-2 pb-2"
                    type="button"
                    v-waves.light
                    >
                    OK
                    </button>
                </router-link>
                </div>
            </div>
            </div>
        </div>
        </b-modal>
        <b-modal
        id="invoice-success-modal"
        class="border-radius-2"
        size="lg"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        centered
        >
        <h5
            class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
            v-on:click="destroyInvoiceElements"
        >
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
        </h5>
        <div class="container text-center">
            <div>
            <h5 class="mt-4 dark-blue-text font-weight-bold">
                このまま依頼を進めてもよろしいでしょうか？<br>
                翌月に請求書にて費用が請求されます。
            </h5>
            <p class="grey-text"></p>
            <div class="row mb-3 mt-5">
                <div class="col-12">
                <button
                    class="btn btn-ds dark-blue white-text pt-2 pb-2"
                    type="button"
                    v-waves.light
                    v-on:click="invoiceSuccessModal"
                >
                    依頼する
                </button>
                </div>
            </div>
            </div>
        </div>
        </b-modal>

        <b-modal
        id="coupon-success-modal"
        class="border-radius-2"
        size="lg"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        centered
        >
        <h5
            class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
            v-on:click="updateUploadAlertModalState"
        >
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
        </h5>
        <div class="container text-center">
            <div v-if="couponData.code != this.code">
            <h5 class="mt-4 dark-blue-text font-weight-bold">
                入力したコードは存在しません。<br>
            </h5>
            </div>
            <div v-else>
            <h5 class="mt-4 dark-blue-text font-weight-bold">
                {{ couponData.amount }}円割引を利用しますか？<br>
            </h5>
            </div>
            <p class="grey-text"></p>
            <div class="row mb-3 mt-5">
                <div v-if="couponData.code == this.code" class="col-12">
                <button
                    class="btn btn-ds dark-blue white-text pt-2 pb-2 couponBtn"
                    type="button"
                    v-waves.light
                    v-on:click="couponSuccessModal()"
                >
                    はい
                </button>
                </div>
                <div v-else class="col-12">
                <button
                    class="btn btn-ds dark-blue white-text pt-2 pb-2 couponBtn"
                    type="button"
                    v-waves.light
                    v-on:click="couponSuccessErrorModal()"
                >
                    はい
                </button>
                </div>
                <div class="col-12" v-if="couponData.code == this.code">
                <button
                class="btn btn-ds light-grey white-text pt-2 pb-2"
                type="button"
                v-waves.light
                v-on:click="cancel()"
                >
                キャンセル
                </button>
            </div>
            </div>
        </div>
        </b-modal>

        <b-modal
        id="error-modal"
        class="border-radius-2"
        size="lg"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        centered
        >
        <h5
            class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
            v-on:click="couponError"
        >
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
        </h5>
        <div class="container text-center">
            <div>
            <h5 class="mt-4 dark-blue-text font-weight-bold">
                半角で入力してください。
            </h5>
            <p class="grey-text"></p>
            </div>
        </div>
        <div class="row mb-3 mt-5">
            <div class="col-12">
            <button
                class="btn btn-ds dark-blue white-text pt-2 pb-2 couponBtn"
                type="button"
                v-waves.light
                v-on:click="couponErrorModal()"
            >
                はい
            </button>
            </div>
        </div>
        </b-modal>
        <image-modal @closeModal="closeModal()" v-if="isOpenModal">
        <img :src="modalImageUrl">
        </image-modal>
    </div>
</template>

<script>
// Import Services
import { cus_stagingService_CreateSRChekoutDetails,cus_stagingService_successInvoicePayment,cus_stagingService_successCoupon,cus_stagingService_GetCanCoupon } from '../../services/customer/staging';
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Stripe Element
import Vue from 'vue';
import { StripePlugin } from '@vue-stripe/vue-stripe';

// Import Modal
import ImageModal from '../../components/customer/Image-Modal.vue';

//import common
import { formatPrice } from '../../common/Utility';

const options = {
  pk: process.env.VUE_APP_Stripe_Key,
};

Vue.use(StripePlugin, options);

export default {
  metaInfo() {
    return {
        title: 'Digital Staging - Staging | Digital Renovation New Request',
    };
  },

  data() {
    return {
      summaryData: null,
      cardNumber: null,
      cardExpiry: null,
      cardCVC: null,
      cardName: null,
      formMessage: null,
      isCardError: false,
      requestId: null,
      code: "",
      couponData: "",
      isOpenModal: false,
      modalImageUrl: null,
    };
  },

  computed: {
    ...mapGetters(['staging']),

    stripeElements() {
      return this.$stripe.elements();
    },
  },
  components: {
    'image-modal': ImageModal,
  },
  watch: {
    code: function(newCode, oldCode) {
      const vali = /^[0-9a-zA-Z]*$/;
        if(!newCode.match(vali)) {
        this.$bvModal.show('error-modal');
        }
    },
  },

  async mounted() {
    await this.createCheckoutDetails();
    console.log('data');
    console.log(this.summaryData);
  },
  methods: {
    cancel() {
      this.$bvModal.hide('coupon-success-modal');
      this.code = "";
      this.couponData = "";
    },
    openModal(imgUrl) {
      this.modalImageUrl = imgUrl;
      this.isOpenModal = true;
    },
    closeModal(){
      this.isOpenModal = false;
    },
    deleCoupon() {
      this.couponData = "";
      this.code = "";
    },
    async codeConfirm() { 
      let _this = this;
      
      await cus_stagingService_GetCanCoupon({
        code: this.code,
        token: this.staging.accessToken,
      })  
        .then((response) => {
          let data = response.data.coupon;
          _this.couponData = data

          this.$bvModal.show('coupon-success-modal');
          cus_stagingService_CreateSRChekoutDetails({
            id: this.staging.info.id,
            token: this.staging.accessToken,
          })
          .then((response) => {
            this.summaryData = response.data.result;
            this.$parent.scrollTop();
          })
          .catch((error) => {
            console.log(error);
          });  
        })  
          .catch(() => {
          this.$bvModal.show('coupon-success-modal');
        }); 
    },
    updateUploadAlertModalState() {
      console.log('close', this.$parent);
      this.$store.state.staging.uploadAlertModal = false;
      this.$bvModal.hide('coupon-success-modal');
    },
    formatPrice,
    async createCheckoutDetails() {
      let _this = this;

      await cus_stagingService_CreateSRChekoutDetails({
        id: _this.staging.info.id,
        token: _this.staging.accessToken,
      })
        .then((response) => {
          this.summaryData = response.data.result;
          this.$parent.scrollTop();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setStripeElements() {
      let style = {
        base: {
          height: '56',
          fontSize: '16px',
          lineHeight: '2.5',
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };

      this.cardNumber = this.stripeElements.create('cardNumber', {
        showIcon: true,
        style,
      });
      this.cardNumber.mount('#card-number');

      this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
      this.cardExpiry.mount('#card-expiry');

      this.cardCVC = this.stripeElements.create('cardCvc', { style });
      this.cardCVC.mount('#card-cvc');
    },
    async showPaymentModal() {
      if (!this.couponData || (((this.summaryData.checkout_data.overall_amount - this.couponData.amount) - (this.summaryData.checkout_data.tax_amount)) + (( this.summaryData.checkout_data.overall_amount - this.couponData.amount - this.summaryData.checkout_data.tax_amount) * 10 / 100)) > 0 ) {
        this.$bvModal.show('payment-information-modal');

        setTimeout(() => {
          this.setStripeElements();
        }, 1000);
      } else {
        let _this = this;
        if (_this.code) {
          await cus_stagingService_successCoupon({
            id: _this.staging.info.id,
            coupon_code: _this.code,
            is_invoice_payment: 0,
            token: _this.staging.accessToken,
          })
        }
        localStorage.removeItem('_digitalRenovationStagingRequestID');
        await cus_stagingService_successInvoicePayment({
          id: _this.staging.info.id,
          coupon_code: _this.code,
          token: _this.staging.accessToken,
        });
        this.$bvModal.show('payment-success-modal');
      }
    },
    showInvoiceModal(invoiceId) {
      this.requestId = invoiceId
      this.$bvModal.show('invoice-success-modal');

      setTimeout(() => {
        this.setStripeElements();
      }, 1000);
    },

    destroyStripeElements() {
      this.$bvModal.hide('payment-information-modal');

      this.cardNumber.destroy();
      this.cardExpiry.destroy();
      this.cardCVC.destroy();
    },
    couponError() {
      this.$bvModal.hide('error-modal');
      this.code = "";
    },
    couponErrorModal() {
      this.$bvModal.hide('error-modal');
      this.code = "";
    },
    couponSuccessErrorModal() {
      this.code = "";
      this.couponData = "";
      this.$bvModal.hide('coupon-success-modal');
    },
    destroyInvoiceElements() {
      this.$bvModal.hide('invoice-success-modal');
    },

    hidePaymentSuccessModal() {
      this.$bvModal.hide('payment-success-modal');
    },

    async finalizeStaging() {
      let _this = this;

      _this.formMessage = null;

      _this.$store.state.modalLoaderMessage = '送信中';
      _this.$bvModal.show('modal-loader');

      if (_this.cardNumber) {
        if (_this.cardExpiry) {
          if (_this.cardCVC) {
            if (_this.cardName) {
              _this.$stripe
                .createPaymentMethod({
                  type: 'card',
                  card: _this.cardNumber,
                })
                .then(async (response) => {
                  let paymentMethodID = response.paymentMethod.id;
                  _this.$stripe
                    .confirmCardPayment(_this.summaryData.pi_client_secret, {
                      payment_method: paymentMethodID,
                    })
                    .then(async (response) => {
                      if (response.error) {
                        _this.isCardError = true;
                      } else {
                        _this.isCardError = false;
                        localStorage.removeItem('_digitalRenovationStagingRequestID');
                      }

                      setTimeout(() => {
                        _this.$bvModal.hide('payment-information-modal');
                        _this.$bvModal.hide('modal-loader');
                        _this.$bvModal.show('payment-success-modal');
                      }, 500);
                    })
                    .catch((error) => {
                      console.log(error);

                      _this.formMessage =
                        'エラーが起こりました。再度お試しください。1';

                      setTimeout(() => {
                        _this.$bvModal.hide('modal-loader');
                      }, 500);
                    });
                })
                .catch((error) => {
                  console.log(error);

                  _this.formMessage =
                    'エラーが起こりました。再度お試しください。2';

                  setTimeout(() => {
                    _this.$bvModal.hide('modal-loader');
                  }, 500);
                });
            } else {
              _this.formMessage = 'カード名義が必要です。';

              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
              }, 500);
            }
          } else {
            _this.formMessage = 'CVCが必要です。';

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
          }
        } else {
          _this.formMessage = '有効期限が必要です。';

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        }
      } else {
        _this.formMessage = 'クレジットカード番号が必要です。';

        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
        }, 500);
      } 
    },
    async invoiceSuccessModal() {
      let _this = this;
      localStorage.removeItem('_digitalRenovationStagingRequestID');
      await cus_stagingService_successInvoicePayment({
        id: _this.requestId,
        token: _this.staging.accessToken,
      });
      
      localStorage.removeItem('_digitalRenovationStagingRequestID');
      await cus_stagingService_successInvoicePayment({
        id: _this.requestId,
        coupon_code: _this.code,
        token: _this.staging.accessToken,
      });  
      this.$router.push('billing-history')
    },

    async couponSuccessModal() {
      let _this = this;
      if (_this.code) {
        let cusStagingServiceSuccessCoupon = await cus_stagingService_successCoupon({
          id: _this.staging.info.id,
          coupon_code: _this.code,
          is_invoice_payment: 0,
          token: _this.staging.accessToken,
        })
        _this.summaryData.pi_client_secret = cusStagingServiceSuccessCoupon.data.result.pi_client_secret
      }
      this.$bvModal.hide('coupon-success-modal');
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */

#step-3-content .order-details-item {
  box-shadow: 0px 0px 25px #00000029;
  border-radius: 18px;
  background: #ffffff;
}

#step-3-content .photo-details-item {
  margin: 20px 0 0 0;
  box-shadow: 0px 0px 25px #00000029;
  border-radius: 18px;
  background: #f5f5f5;
  height: 200px;
}

#step-3-content .photo-details-item .photo {
  position: relative;
  padding: 0;
  height: 100%;
  width: 100%;
}

#step-3-content .photo-details-item .photo img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  z-index: 100;
}

@media only screen and (max-width: 767px) {
  #step-3-content .photo-details-item .details {
    margin-top: 10px;
    padding: 10px;
  }
  #step-3-content .photo-details-item {
    height: auto;
  }
}

.detail-font {
  line-height: 1;
  font-size: 13px;
  padding-bottom:0px;
}

.staging-detail {
  padding-left:0px;
}

.box-padding {
  padding:20px;
  min-width:290px;
  max-width:900px;
}

.img-padding {
  max-width:900px;
  padding-left:15px;
  margin-bottom:12px;
}

.title-font {
  font-size:1.1rem;
}

#step-3-content .total-price {
  padding: 6px 0 6px 0;
  background: #fff;
  border: #055ab8 2px solid;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 20px;
  margin: 0px;
}

.coupon-text {
  height: 30px;
  width: 220px;
  border: 1px solid #ced4da;
}

@media only screen and (max-width: 767px) {
  #step-3-content .total-price {
    margin: 20px 2px 20px 2px;
  }
}

#step-3-content .total-price div:nth-child(2) {
  text-align: right;
}

@media only screen and (max-width: 1440px) {
  #step-3-content .photo-details-item .photo .b-aspect {
    height: 150px !important;
  }
  .coupon-text {
    width: 215px;
  }
}

@media only screen and (max-width: 1199px) {
  .price-area {
    padding-left: 15px;
  }
}
.btn1,
a.btn1,
button.btn1 {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  padding: 1rem 4rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #212529;
  border-radius: 0.5rem;
}
.coupon-button {
  width: 60px;
  height: 20px;
  color: #fff;
  background: #055ab8;
  border: none;
  margin-top: 5px;
  border-radius: 5px;
}
.couponBtn {
  margin-bottom: 5px;
}
.coupon-area {
  display: flex;
  align-items: center;
}
.coupon-prompt {
  font-size: 14px;
  font-weight: 700;
}
.coupon-title {
  text-align: left;
}
.btn-coupon {
  background: #055ab8;
  border: 1px solid #055ab8;
  color: #ffff;
  border-radius: 3px;
}
.coupon-warappaer {
  margin-left: 15px;
}
.cou-btn {
  background: #CC0000;
  border: none;
  color: #ffff;
  border-radius: 3px;
}
.button-cou {
  margin-left: 29px;
}
</style>
